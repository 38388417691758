import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Cookie from "js-cookie";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import { depotURL } from "../../../static/constants/backendRoutes";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import { multiDayUploadStepInfo } from "../../../static/constants/stepInfo";
import {
  unitLargeAbbr,
  unitLargeMap,
} from "../../../static/constants/systems_of_measurement";
import { NextPageButton } from "../../assessmentPages/commonComponents";
import UseAuth from "../../auth/useAuth";
import { MultiDayUploadAnalysisStepper } from "../../secondary/steppers";
import Subheader from "../../secondary/subheader";
import {
  getUnits,
  unitMiles,
  unitPerMile,
} from "../../secondary/unitConversions";
import {
  errorHandler,
  getLabel,
  getLocalData,
  partialClearLocalData,
  roundNumber,
  storeLocalData,
  unitWrapper,
} from "../../utils";
import SimulationSubtitle from "../dialogs/simulationSubtitle";

const STEP_NUMBER = 0;

const ManualUploadTable = ({
  importedData,
  setImportedData,
  onFileImport,
  onTemplateDownload,
  depotLookup,
  snackBarElement,
}) => {
  const [editData, setEditData] = useState(null); // Stores the row being edited, might use it later
  const [anchorEl, setAnchorEl] = useState(null); // Anchor for menu
  const [menuRow, setMenuRow] = useState(null); // Tracks the row for the menu
  const [subheaderContent, setSubheaderContent] = useState([]);

  const units = getUnits();

  const handleMenuOpen = useCallback((event, row) => {
    setAnchorEl(event.currentTarget);
    setMenuRow(row);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    setMenuRow(null);
  }, []);

  const handleEditOpen = () => {
    setEditData(menuRow?.original);
    handleMenuClose();
  };

  const handleEditClose = () => {
    //might use later
    setEditData(null);
  };

  const handleSaveEdit = () => {
    if (!editData.blockId.trim()) {
      snackBarElement.current.displayToast("Block ID cannot be empty.");
      return;
    }
    if (
      !editData.depotDepartureDateTime ||
      !dayjs(editData.depotDepartureDateTime).isValid()
    ) {
      snackBarElement.current.displayToast("Departure Datetime is invalid.");
      return;
    }
    if (
      !editData.depotArrivalDateTime ||
      !dayjs(editData.depotArrivalDateTime).isValid()
    ) {
      snackBarElement.current.displayToast("Arrival Datetime is invalid.");
      return;
    }

    if (isNaN(editData.distance) || editData.distance < 0) {
      snackBarElement.current.displayToast(
        "Distance must be a valid positive number."
      );
      return;
    }

    const updatedData = importedData.map((row) =>
      row.blockId === editData.blockId ? editData : row
    );

    setImportedData(updatedData);
    storeLocalData("multiDayUpload", { data: updatedData });
    handleEditClose();
  };

  const handleDeleteRow = () => {
    if (window.confirm("Are you sure you want to delete this row?")) {
      const updatedData = importedData.filter(
        (_row, index) => index != menuRow.index
      );
      storeLocalData("multiDayUpload", {
        data: updatedData,
      });

      handleMenuClose();
      setImportedData(updatedData);
    }
  };

  const columns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */ () => [
      {
        id: "actions",
        header: "Actions",
        Cell: ({ row }) => (
          <div>
            <IconButton
              onClick={(event) => handleMenuOpen(event, row)}
              size="small"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && menuRow?.id == row.id}
              onClose={handleMenuClose}
            >
              {/* <MenuItem onClick={handleEditOpen}>
                <EditIcon fontSize="small" style={{ marginRight: 8 }} />
                Edit
              </MenuItem> */}
              <MenuItem onClick={handleDeleteRow}>
                <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
                Delete
              </MenuItem>
            </Menu>
          </div>
        ),
      },
      { accessorKey: "vehicleId", header: getLabel("block_id_original") },
      { accessorKey: "blockId", header: getLabel("block_id") },
      {
        accessorKey: "depotDepartureDateTime",
        header: "Depot Departure Datetime",
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        sortingFn: "datetime",
        filterVariant: "datetime",
      },
      {
        accessorKey: "depotArrivalDateTime",
        header: "Depot Return Datetime",
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        sortingFn: "datetime",
        filterVariant: "datetime",
      },
      {
        header: "Depot",
        accessorKey: "arrivalDepot",
        Cell: ({ cell }) => depotLookup[cell.getValue()] ?? "Loading...",
        filterVariant: "select",
        filterSelectOptions: Object.entries(depotLookup).map(
          ([value, label]) => ({ value, label })
        ),
        muiFilterTextFieldProps: { sx: { maxWidth: "20ch" } },
      },
      {
        accessorKey: "distance",
        header: "Distance",
        accessorFn: (row) => roundNumber(unitMiles(row.distance), 1),
        Cell: ({ cell }) => (
          <>
            {cell.getValue()} {unitWrapper(unitLargeAbbr[units])}
          </>
        ),
      },
    ],
    [importedData, menuRow, depotLookup]
  );

  const table = useMaterialReactTable({
    ...materialReactTableOptions(),
    columns,
    data: importedData,
    enableEditing: false,
    enableRowSelection: false,
    renderRowActions: undefined,
    renderEmptyRowsFallback: () => (
      <NoDataContent
        onFileImport={onFileImport}
        onTemplateDownload={onTemplateDownload}
      />
    ),
  });

  const computeTripMetrics = () => {
    const numVehicles = new Set(importedData.map((row) => row.vehicleId)).size;
    const totalDistance = importedData.reduce(
      (sum, row) => sum + row.distance,
      0
    );
    const numTrips = importedData.length;
    const longestTrip = Math.max(...importedData.map((row) => row.distance), 0);

    setSubheaderContent([
      {
        value: Math.round(numVehicles).toLocaleString(),
        label: `Number of Vehicles`,
      },
      {
        value: `~\xa0${Math.round(totalDistance).toLocaleString()}`,
        label: `Total Distance (${unitLargeMap[units]})`,
      },
      {
        value: Math.round(numTrips).toLocaleString(),
        label: `Number of Trips`,
      },
      {
        value: `~\xa0${(
          Math.round(totalDistance / numTrips) || 0
        ).toLocaleString()}`,
        label: `Average Trip Distance (${unitLargeMap[units]})`,
      },
      {
        value: `~\xa0${Math.round(longestTrip).toLocaleString()}`,
        label: `Longest Trip (${unitLargeMap[units]})`,
      },
    ]);
  };

  // Call computeTripMetrics when tableData updates
  useEffect(() => {
    computeTripMetrics();
  }, [importedData]);

  return (
    <div>
      <Subheader content={subheaderContent} />
      <MaterialReactTable table={table} />

      {/* Edit Dialog */}
      {editData && (
        <Dialog open={!!editData} onClose={handleEditClose}>
          <DialogTitle>Edit Row</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Vehicle ID"
              fullWidth
              value={editData.vehicleId}
              onChange={(e) =>
                setEditData({ ...editData, vehicleId: e.target.value })
              }
            />
            <TextField
              margin="dense"
              label="Block ID"
              fullWidth
              value={editData.blockId}
              onChange={(e) =>
                setEditData({ ...editData, blockId: e.target.value })
              }
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box display="flex" flexDirection="column" gap={2}>
                <DateTimePicker
                  label="Depot Departure Datetime"
                  value={
                    editData.depotDepartureDateTime
                      ? dayjs(editData.depotDepartureDateTime)
                      : null
                  }
                  onChange={(newValue) =>
                    setEditData({
                      ...editData,
                      depotDepartureDateTime: newValue
                        ? newValue.toISOString()
                        : "",
                    })
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth margin="dense" />
                  )}
                />

                <DateTimePicker
                  label="Depot Return Datetime"
                  value={
                    editData.depotArrivalDateTime
                      ? dayjs(editData.depotArrivalDateTime)
                      : null
                  }
                  onChange={(newValue) =>
                    setEditData({
                      ...editData,
                      depotArrivalDateTime: newValue
                        ? newValue.toISOString()
                        : "",
                    })
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth margin="dense" />
                  )}
                />
              </Box>
            </LocalizationProvider>

            {/* <TextField
              margin="dense"
              label="Return Depot"
              fullWidth
              value={editData.arrivalDepot}
              select
              onChange={(e) =>
                setEditData({ ...editData, arrivalDepot: e.target.value })
              }
            >
              {Object.entries(depotLookup).map(([depot_id, depot_name]) => (
                <MenuItem key={depot_id} value={depot_id}>
                  {depot_name}
                </MenuItem>
              ))}
            </TextField> */}
            <TextField
              margin="dense"
              label="Distance"
              fullWidth
              value={editData.distance}
              onChange={(e) =>
                setEditData({ ...editData, distance: e.target.value })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose}>Cancel</Button>
            <Button onClick={handleSaveEdit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

const NoDataContent = ({ onFileImport, onTemplateDownload }) => (
  <Grid
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    style={{ padding: "20px" }}
  >
    <Grid item style={{ marginBottom: "10px" }}>
      <Button
        variant="outlined"
        sx={{ width: "200px", borderRadius: "20px" }}
        component="label"
      >
        Import Excel File
        <input
          type="file"
          accept=".xlsx"
          hidden
          onChange={onFileImport}
          onClick={(e) => (e.target.value = "")}
        />
      </Button>
    </Grid>
    <Grid item>
      <Button
        variant="outlined"
        sx={{ width: "200px", borderRadius: "20px" }}
        onClick={onTemplateDownload}
      >
        Download Template File
      </Button>
    </Grid>
  </Grid>
);

const downloadTemplate = () => {
  const headers = [
    "Vehicle ID",
    "Block ID",
    "Depot Departure Datetime",
    "Depot Return Datetime",
    "Distance",
  ];

  const worksheet = XLSX.utils.aoa_to_sheet([headers]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Template");
  XLSX.writeFile(workbook, "template.xlsx");
};

/**
 *
 * @param {*} event
 * @param {*} setImportedData
 * @param { import("react").MutableRefObject<{displayToast: import("/Users/work/Documents/Microgrid/github/Simulator-SaaS-Frontend-DevOps/src/components/secondary/snackBar").displayToast}>} snackBarElement
 * @param {*} depotId
 */
const handleFileImport = (
  event,
  setImportedData,
  snackBarElement,
  depotId,
  setIsLoading
) => {
  setIsLoading(true);
  const file = event.target.files[0];
  if (!file) {
    snackBarElement.current.displayToast("File not found", "error", 5000);
    setIsLoading(false);
    return;
  }

  // Validate file type
  const fileType = file.name.split(".").pop().toLowerCase();
  if (fileType != "xlsx") {
    snackBarElement.current.displayToast(
      "Invalid file format. Please upload an .xlsx file.",
      "error",
      5000
    );
    setIsLoading(false);
    return;
  }

  const reader = new FileReader();
  reader.onload = (e) => {
    const data = e.target.result;
    const workbook = XLSX.read(data, { cellDates: true });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
    // Validate required columns
    const requiredColumns = [
      "Vehicle ID",
      "Block ID",
      "Depot Departure Datetime",
      "Depot Return Datetime",
      "Distance",
    ];

    if (!(jsonData.length > 1)) {
      snackBarElement.current.displayToast(
        "No data found in the uploaded file.",
        "error",
        5000
      );
      setIsLoading(false);
      return;
    }

    const missingColumns = requiredColumns.filter(
      (col) => !Object.keys(jsonData[0] || {}).includes(col)
    );

    if (missingColumns.length) {
      snackBarElement.current.displayToast(
        `Missing required columns: ${missingColumns.join(
          ", "
        )}. Please check the file.`,
        "error",
        5000
      );
      setIsLoading(false);
      return;
    }

    let formattedData = [],
      error = "", //if an error occurs in the loop, set this to the string of the error, and break out of the loop
      meanVelocity = 0,
      meanAcceleration = 0;

    // perform type validations before the sort (to avoid errors)
    for (const i in jsonData) {
      const row = jsonData[i];

      // existence and type validation
      if (!row["Vehicle ID"])
        error = `Vehicle ID is missing in row ${row.__rowNum__}`;
      else if (!row["Block ID"])
        error = `Block ID is missing in row ${row.__rowNum__}`;
      else if (isNaN(row["Distance"]))
        error = `Invalid distance value in row ${row.__rowNum__}, must be a number`;
      else if (
        !(row["Depot Departure Datetime"] instanceof Date) ||
        !(row["Depot Return Datetime"] instanceof Date)
      )
        error = `Invalid datetime format in row ${row.__rowNum__}. Please use an excel readible DateTime format`;

      // If there is any error, exit the loop.
      if (error) {
        console.log(row);
        break;
      }
    }

    if (error) {
      snackBarElement.current.displayToast(error, "error", 5000);
      setIsLoading(false);
      return;
    }

    // sort by departure time, so that we can efficiently check for overlapping time ranges
    jsonData.sort(
      (a, b) => a["Depot Departure Datetime"] - b["Depot Departure Datetime"]
    );

    const overlapVehicleTimeRangeCheck = {};
    const duplicateBlockIdCheck = new Set();
    for (const i in jsonData) {
      const row = jsonData[i];
      // duplicate blockId check
      if (duplicateBlockIdCheck.has(row["Block ID"])) {
        error = `Cannot contain duplicate block ID: ${row["Block ID"]}`;
        break;
      } else duplicateBlockIdCheck.add(row["Block ID"]);

      // overlapping time range check
      const {
        "Vehicle ID": vehicleId,
        "Depot Departure Datetime": departureTime,
        "Depot Return Datetime": arrivalTime,
      } = row;
      if (departureTime > arrivalTime)
        error = `Departure time must come before return time for block ID ${row.blockId}.`;
      if (arrivalTime - departureTime > 129600000)
        error = `Duration cannot exceed 36 hours for block ID ${row.blockId}.`;

      // overlapping time range check with previous row of same vehicle ID
      const prevTrip = overlapVehicleTimeRangeCheck[vehicleId];
      if (
        prevTrip &&
        ((departureTime >= prevTrip.departureTime &&
          departureTime < prevTrip.arrivalTime) ||
          (arrivalTime > prevTrip.departureTime &&
            arrivalTime <= prevTrip.arrivalTime) ||
          (departureTime <= prevTrip.departureTime &&
            arrivalTime >= prevTrip.arrivalTime))
      ) {
        error = `Time overlap detected for vehicle ID ${vehicleId}.`;
        break;
      }
      overlapVehicleTimeRangeCheck[vehicleId] = {
        departureTime,
        arrivalTime,
      };

      // If there is any error, exit the loop.
      if (error) break;

      // Warning checks (remove row, but don't exit the loop)
      const distance = unitPerMile(row.Distance);
      // min distance check (1 mile)
      if (distance < 1) continue;
      else if (arrivalTime - departureTime < 180000) continue; // min time check (3 minutes); skip this row

      // now that all row removals have occurred, perform any additional calculations
      const velocity = distance / (arrivalTime - departureTime);
      const acceleration = (2 * velocity) / (arrivalTime - departureTime);
      meanVelocity += velocity;
      meanAcceleration += acceleration;

      // format the data
      formattedData.push({
        vehicleId: String(vehicleId).replaceAll("_", "-") || "",
        blockId: row["Block ID"] || "",
        depotDepartureDateTime: departureTime,
        depotArrivalDateTime: arrivalTime,
        arrivalDepot: depotId, //Temporary hard-set of depotId to the selected depot
        distance: unitPerMile(row["Distance"]) || "",
        velocity,
        acceleration,
      });
    }

    if (error) {
      snackBarElement.current.displayToast(error, "error", 5000);
      setIsLoading(false);
      return;
    }

    if (formattedData.length > 100) {
      // only remove percentile data if the size of the input is significant
      formattedData.sort((a, b) => a.velocity - b.velocity);
      const percentileVelocity =
        formattedData[Math.ceil(formattedData.length * 0.97)].velocity;
      formattedData.sort((a, b) => a.acceleration - b.acceleration);
      const percentileAcceleration =
        formattedData[Math.ceil(formattedData.length * 0.97)].acceleration;
      formattedData = formattedData.filter(
        (row) =>
          row.velocity < percentileVelocity &&
          row.acceleration < percentileAcceleration
      );
    }

    if (jsonData.length != formattedData.length) {
      //if rows were removed, but did not raise error flag, raise a warning to notify user
      const nRowsRemoved = jsonData.length - formattedData.length;
      snackBarElement.current.displayToast(
        `We have successfully processed your data and removed ${nRowsRemoved.toLocaleString()} trip${
          nRowsRemoved == 1 ? "" : "s"
        } that did not meet our data quality standards or analysis minimum criteria. 
This may include trips with zero movement, unusually high speeds or accelerations, or very short distances and durations.`,
        "info",
        5000,
        { sx: { width: "40%" } }
      );
    }

    setImportedData(formattedData);
    storeLocalData("multiDayUpload", { data: formattedData });
    setIsLoading(false);
    return;
  };

  reader.readAsArrayBuffer(file);
};

const MultiDayUpload = () => {
  const [importedData, setImportedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [depotId, setDepotId] = useState("");
  const [depotLookup, setDepotLookup] = useState({});
  const { snackBarElement } = useContext(SnackBarContext);
  const projectId = Cookie.get("project_id");

  useEffect(() => {
    document.querySelector("#App").scrollIntoView(); //scrolls to top of app
    const fetchImportedData = async () => {
      const headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };

      let depot_lookup = {};
      //fetches all the depots associated with the selected project (technically, currently unnecessary)
      fetch(`${depotURL}?project_id=${projectId}`, {
        method: "GET",
        headers: headers,
      })
        .then((res) => {
          if (res.ok) {
            res.json().then(({ data: depots }) => {
              depots.forEach((depot) => (depot_lookup[depot.id] = depot.name));
              setDepotLookup(depot_lookup);
            });
          } else errorHandler(res, snackBarElement);
        })
        .catch((err) => {
          console.log(err);
          snackBarElement.current.displayToast(
            "Something went wrong in depot fetch",
            "error"
          );
        });

      const { data } = await getLocalData("multiDayUpload", "data");
      if (data) setImportedData(data);

      const {
        data: { depot_id },
      } = await getLocalData("simulation", "data");
      setDepotId(depot_id);
      setIsLoading(false);
    };

    fetchImportedData();
  }, []);

  const clearImportedData = () => {
    setImportedData([]);
  };

  const handleContinueClick = async () => {
    try {
      setIsLoading(true);

      if (importedData.length == 0) {
        snackBarElement.current.displayToast(
          "No data has been imported yet. Please import a file first.",
          "error",
          5000
        );
        return;
      }

      //clear out the future pages' frontend data
      partialClearLocalData([
        "vehicleAssignment",
        "fleetProjection",
        "historicalAnalysisData",
        "scheduleGeneration",
      ]);

      navigate(multiDayUploadStepInfo[STEP_NUMBER + 1].route);
    } catch (error) {
      console.error("Error moving to the next step:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        margin: "20px auto",
        padding: "20px",
        width: "95%",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      <br />
      <br />
      <MultiDayUploadAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      <br />
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          {/* replaces all spaces with non-breakling space equivalents */}
          {multiDayUploadStepInfo[STEP_NUMBER].label.replaceAll(" ", "\xa0")}
        </Typography>
        <SimulationSubtitle />
      </Container>
      <br />
      <Container fixed maxWidth="xl">
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
          <ManualUploadTable
            importedData={importedData}
            setImportedData={setImportedData}
            onFileImport={async (event) => {
              await handleFileImport(
                event,
                setImportedData,
                snackBarElement,
                depotId,
                setIsLoading
              );
            }}
            onTemplateDownload={downloadTemplate}
            depotLookup={depotLookup}
            snackBarElement={snackBarElement}
          />
        </Paper>
      </Container>
      <br /> <br />
      <Container fixed>
        <Stack
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={2}
          alignItems="center"
        >
          <Button
            variant="outlined"
            className="btn"
            fullWidth
            onClick={clearImportedData}
          >
            Clear Data
          </Button>
          <NextPageButton
            fullWidth
            onClick={handleContinueClick}
            loading={isLoading}
          >
            Continue to {multiDayUploadStepInfo[STEP_NUMBER + 1].label}
          </NextPageButton>
        </Stack>
      </Container>
      {/* Loading */}
      <Backdrop
        open={isLoading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Container alignitems="center" justify="center" aligncontent="center">
          <Container align="center">
            <CircularProgress color="inherit" />
          </Container>
          <br />
          <Container align="center">
            <Typography variant="h5">Loading...</Typography>
          </Container>
        </Container>
      </Backdrop>
    </div>
  );
};

export default MultiDayUpload;
