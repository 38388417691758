import MaterialTable, { MTableToolbar } from "@material-table/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Delete from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactSpreadsheetImport, StepType } from "react-spreadsheet-import";

import { localDb } from "../../../contexts/localDb";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import {
  depotURL,
  fleetManualDownloadURL,
  resourceURL,
  simulationURL,
} from "../../../static/constants/backendRoutes";
import stepInfo from "../../../static/constants/stepInfo";
import {
  unitLargeAbbr,
  unitSmallMap,
} from "../../../static/constants/systems_of_measurement";
import { ReactSpreadSheetImportTheme } from "../../../static/constants/theme";
import UseAuth from "../../auth/useAuth";
import { AssessmentAnalysisStepper } from "../../secondary/steppers";
import TimedCircularProgress from "../../secondary/timedCircularProgress";
import {
  getUnits,
  unitFeet,
  unitMiles,
  unitPerMile,
} from "../../secondary/unitConversions";
import {
  AMPM_to_Military,
  Icons,
  Military_to_AMPM,
  Military_to_MFM,
  errorHandler,
  getLabel,
  getLocalData,
  getMasterData,
  handleKey,
  partialClearLocalData,
  readExcelFileAsync,
  roundNumber,
  storeLocalData,
  unitWrapper,
} from "../../utils";
import { NextPageButton } from "../commonComponents";
import SimulationSubtitle from "../dialogs/simulationSubtitle";
import CoordInput from "../tables/coordInput";

const STEP_NUMBER = 0;

/**
 * made a function, as it is no longer truely constant due to the cookie fetching
 * fields used to define reactSpreadSheetImport columns (and part of the first 4 material-table columns)
 * based off https://github.com/UgnisSoftware/react-spreadsheet-import#fields
 */
export const fleetManualFields =
  /** @returns {import("react-spreadsheet-import/types/types").Field<any>[]} */ () => [
    {
      label: "Route",
      key: "block_id_original",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["Route", "Route Id"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "Bus1",
      // Can have multiple validations that are visible in Validation Step table.
      // custom validations can be handled in the rowHook function
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Route is required",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          // level: "error",
        },
      ],
    },
    {
      label: getLabel("block_id"),
      key: "blockId",
      // Allows for better automatic column matching. Optional.
      alternateMatches: [
        "Block/Route Id",
        getLabel("block_id").replaceAll("\xa0", " "),
      ],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "Bus1-AM",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: `${getLabel("block_id")} is required`,
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          // level: "error",
        },
        {
          // Can be "required" / "unique" / "regex"
          rule: "unique",
          errorMessage: `${getLabel("block_id")} must be unique`,
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          // level: "error",
        },
      ],
    },
    {
      label: "Start Time",
      key: "dh_st_time",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["Start Time", "DepartTime"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "9:00",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Start Time is required",
        },
        {
          // Can be "required" / "unique" / "regex"
          rule: "regex",
          value: /^([01]\d|2[0-3]):([0-5]\d)$/,
          errorMessage: "Start Time must be in military time format (hh:mm)",
        },
        {
          // Can be "required" / "unique" / "regex"
          rule: "regex",
          value: /^(?!00:(0\d|10)$)/,
          errorMessage: "Start Time must be greater than 00:10",
        },
      ],
    },
    {
      label: "End Time",
      key: "dh_end_time",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["End Time", "ArriveTime"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "17:00",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "End Time is required",
        },
        {
          // Can be "required" / "unique" / "regex"
          rule: "regex",
          value: /^([01]\d|2[0-3]):([0-5]\d)$/,
          errorMessage: "End Time must be in military time format (hh:mm)",
        },
      ],
    },
    {
      label: "Distance",
      key: "distance",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["Distance"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: 12,
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Distance is required",
        },
      ],
    },
  ];

export default function FleetManual() {
  const [initialFormData, setInitialFormData] = useState({}); //for auto-filling fields on copy/add row

  const [selectedRows, setSelectedRows] = useState([]); //rows to run "routeEnergyModeler" on
  const [buttonLoading, setButtonLoading] = useState(false); //for displaying loading effect
  const [simDepot, setSimDepot] = useState(undefined);
  const [depotLookup, setDepotLookup] = useState({}); //an object that maps depot numbers to names, for depot dropdown in table

  const [vehicleResource, setVehicleResource] = useState([]); //list of vehicle resources assigned to project (i.e. depots, type, amount, etc.)
  const [allVehicles, setAllVehicles] = useState([]); //list of all vehicle specs in the masterdata database
  const [fleetData, setFleetData] = useState([]); //primary route table data

  const [activeRow, setActiveRow] = useState(undefined); //active row (for the coordinate table);
  const [coordTableDialogOpen, setCoordTableDialogOpen] = useState(false);

  const [initialSpreadsheetState, setInitialSpreadsheetState] =
    useState(undefined);
  const [isSpreadSheetImportOpen, setIsSpreadSheetImportOpen] = useState(false);
  const [isBulkDeleteOpen, setIsBulkDeleteOpen] = useState(false);

  const [isNavDisabled, setIsNavDisabled] = useState(false);

  const [project, setProject] = useState(undefined);

  const materialTableRef = useRef(); //to be used when duplicating rows... Might be usable to persist sort between renders (i.e. Updates)

  const { snackBarElement } = useContext(SnackBarContext);

  const navigate = useNavigate();
  const units = getUnits();

  useEffect(() => {
    /**
     * retrieves the blocks and project from the local DB and then
     * fetches and stores all the depots, vehicles, and chargers
     * that are associated with the selected project from the backend
     * and does something else?
     */
    async function fetchData() {
      try {
        //retrieves the project JSON from the localDb
        if (!UseAuth("get")) {
          window.location.assign("/login");
        }
        const { data: currentProject } = await getLocalData("project", "data");
        const {
          data: { depot_id },
        } = await getLocalData("simulation", "data");
        if (!currentProject) {
          //if no current project stored in frontend
          snackBarElement.current.displayToast(
            "Unable to Find Current Simulation's Project, Please Return to Fleet Operation Input and Try Again",
            "error"
          );
          return;
        }
        setSimDepot(depot_id);
        setProject(currentProject); //set default project value here
        setInitialFormData({ projectType: currentProject.type, coords: [] }); //set initial "route type" column value to match project type

        //manualInput's indexDb is stored differently than the other's data
        const storedFleet = await localDb?.manualInput
          .toCollection()
          .toArray((storedRows) => storedRows.map((entry) => entry.data));
        setFleetData(storedFleet); //if there is any manual entry data previously stored in indexdb, use it
        setSelectedRows(storedFleet.filter((row) => row?.tableData?.checked));

        let headers = {
          Authorization: `Token ${UseAuth("get")}`,
          "Content-Type": "application/json",
        };

        //todo: advanced error handling
        //gets all the vehicle resources associated with the current project (i.e. assigned depots, vehicle count, type, etc.)
        fetch(`${resourceURL}?type=2&project_id=${currentProject.id}`, {
          method: "GET",
          headers: headers,
        })
          .then((res) => {
            if (res.ok)
              res.json().then(({ data: vehicleResources }) => {
                setVehicleResource(vehicleResources);
                getMasterData(snackBarElement, {
                  models: vehicleResources.map((veh) => veh.resource_id),
                  onSuccess: setAllVehicles,
                });
              });
            else
              errorHandler(
                res,
                snackBarElement,
                `Something Went Wrong Fetching Vehicle Resources`
              );
          })
          .catch((err) => {
            setVehicleResource([]);
            console.log(err);
            snackBarElement.current.displayToast(
              `Something Went Wrong Fetching Vehicle Resources`,
              "error",
              7500
            );
          });

        //todo: advanced error handling
        //fetches all the depots associated with the selected project
        fetch(`${depotURL}?project_id=${currentProject.id}`, {
          method: "GET",
          headers: headers,
        })
          .then((res) => {
            if (res.ok) {
              return res.json().then(({ data: depots }) => {
                const depot_lookup = depots.reduce(
                  (lookup, depot) => ({ ...lookup, [depot.id]: depot.name }),
                  {}
                );

                setDepotLookup(depot_lookup);
              });
            }
            // else
            errorHandler(
              res,
              snackBarElement,
              `Something went wrong fetching project's depots`
            );
          })
          .catch((err) => {
            console.log(err);
            setDepotLookup([]);
            snackBarElement.current.displayToast(
              `Something Went Wrong Fetching Depot`,
              "error",
              7500
            );
          });
      } catch (e) {
        console.log(e);
        snackBarElement.current.displayToast(
          `Something Went Wrong Fetching Vehicle Data`,
          "error",
          7500
        );
      }
    }
    fetchData();
  }, []);

  /** Downloads an example Excel Document */
  const handleDownload = () => {
    let headers = {
      Authorization: `Token ${UseAuth("get")}`,
    };
    axios({
      url: fleetManualDownloadURL,
      method: "GET",
      headers: headers,
      responseType: "blob",
    }).then((response) => {
      const downloadURL = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadURL;
      link.setAttribute("download", `fleet_operation_template.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  /**
   * reads the imported excel file,
   * formats the input times to military, and opens the react spreadsheet import dialog
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */
  async function handleImportExcel(e) {
    let data = await readExcelFileAsync(e);
    data = data.map((row) =>
      row.map((cell) => {
        //in theory, all cells should be strings at this stage, but just to be safe, check the type
        if (typeof cell == "string") cell = cell.trim();
        if (
          // check for the 01:00:00 am format, and handle if it is in said format
          cell.match(/^([0|1]?\d|2[0-3]):([0-5]\d)(:\d\d)?( ?(A|a|P|p)(M|m))?$/)
        ) {
          return AMPM_to_Military(cell);
        }
        return cell;
      })
    );
    const initialState = { type: StepType.selectHeader, data: data };
    setInitialSpreadsheetState(initialState);
    setIsSpreadSheetImportOpen(true);
  }

  /**
   * checks if endTime is ever less than start time, and if so, adds 24 hours to the end time
   * @param {import("react-spreadsheet-import/types/types").Data<string>[]} tableData
   * @param {import("react-spreadsheet-import/types/types").RawData[]} rawData
   * @param {import("react-spreadsheet-import/types/steps/MatchColumnsStep/MatchColumnsStep").Columns[]} columns
   * @returns {Promise<import("react-spreadsheet-import/types/types").Data<string>[]>}
   */
  function onMatchColumnsStep(tableData) {
    if (
      tableData.some(
        (row) =>
          Military_to_MFM(row.dh_st_time) > Military_to_MFM(row.dh_end_time)
      )
    )
      snackBarElement?.current?.displayToast(
        <>
          At least one row has an end time come before start time.
          <br />
          If not changed, it will be assumed that the end time occurs the next
          day.
        </>,
        "warning",
        7500
      );

    tableData.forEach((row) => {
      if (!row.block_id_original) row.block_id_original = row.blockId;
    });
    return tableData;
  }

  /** closes the excel import dialog */
  function onSpreadSheetImportClose() {
    document.getElementById("file_input").value = null; //allows user to upload another file, on cancel
    setIsSpreadSheetImportOpen(false);
  }

  /** sets the data based off the excel import */
  function handleSpreadSheetImportSubmit(data, _file) {
    const defaultVehicleResource =
      vehicleResource.find((veh) => veh.depot_id == simDepot) || // selects the first resource belonging to the selected depot
      vehicleResource[0]; //as a fall-back, picks the first vehicle that the project has access to, and uses it to auto-fill missing fields of the imported excel sheet
    const defaultVehicleSpecs = allVehicles.find(
      //gets the size/type of the default imported vehicle info
      (vehicle) => vehicle.model == defaultVehicleResource.resource_id
    );

    const fleetData = data.validData.map((row, index) => ({
      ...row,
      distance: unitPerMile(row.distance),
      //auto-fills some fields based on previous page's selected project
      projectType: project?.type,
      endDepot: defaultVehicleResource.depot_id,
      veh_type: defaultVehicleSpecs.type,
      size: defaultVehicleSpecs.size,
      tableData: { id: index, checked: true },
    }));

    setSelectedRows(fleetData);
    setFleetData(fleetData); //populates the material table data
    localDb.manualInput.bulkAdd(
      //adds all the imported rows to indexDb at once
      /// .map puts the data into the format used by indexDb
      fleetData.map((item) => ({ blockId: item.blockId, data: item }))
    );

    onSpreadSheetImportClose();
  }

  /**
   * handles import warnings outside of what the default params allow
   * @param {Data<string>} rowData
   * @param {(fieldKey: string, error: Info) => void} addError
   */
  function onSpreadSheetImportRowChange(rowData, addError) {
    if (
      Military_to_MFM(rowData.dh_st_time) > Military_to_MFM(rowData.dh_end_time)
    ) {
      addError("dh_end_time", {
        message:
          "Warning: If end time comes after start time, it will take place the next day",
        level: "warning",
      });
    }

    if (isNaN(rowData.distance))
      addError("distance", {
        message: "Distance must be a number",
        level: "error",
      });
    else if (rowData.distance <= 0)
      addError("distance", {
        message: "Distance must be greater than 0",
        level: "error",
      });
    else if (rowData.distance > 1000)
      addError("distance", {
        message: "Distance must be less than 1000",
        level: "error",
      });

    return rowData;
  }

  /** @type {import("@material-table/core").Column<never>[]} */
  const columns = [
    {
      title: fleetManualFields()[0].label,
      field: fleetManualFields()[0].key,
      validate: (rowData) =>
        rowData?.[fleetManualFields()[0].key] ? true : "Enter Route",
      editable: "onAdd",
      editComponent: (
        props //takes care of when we create a new project or edit an existing table
      ) => (
        <TextField
          id={fleetManualFields()[0].key}
          name={fleetManualFields()[0].key}
          label={fleetManualFields()[0].label}
          variant="standard"
          required
          error={!props?.value?.replace(/\s/g, "")?.length ? true : false}
          helperText={
            !props?.value?.replace(/\s/g, "")?.length ? "Empty Value" : ""
          }
          value={props.value ? props.value : ""}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          autoFocus //cursor moves to this field on edit
        />
      ),
      hidden: fleetData?.[0] && !fleetData[0]?.[fleetManualFields()[0].key],
    },
    {
      title: fleetManualFields()[1].label,
      field: fleetManualFields()[1].key,
      validate: (rowData) =>
        rowData?.[fleetManualFields()[1].key] ? true : "Enter Id",
      editable: "onAdd",
      editComponent: (
        props //takes care of when we create a new project or edit an existing table
      ) => (
        <TextField
          id={fleetManualFields()[1].key}
          name={fleetManualFields()[1].key}
          label={fleetManualFields()[1].label}
          variant="standard"
          required
          error={!props?.value?.replace(/\s/g, "")?.length ? true : false}
          helperText={
            !props?.value?.replace(/\s/g, "")?.length ? "Empty Value" : ""
          }
          value={props.value ? props.value : ""}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          autoFocus //cursor moves to this field on edit
        />
      ),
    },
    {
      title: "Departure Time",
      field: "dh_st_time",
      render: (rowData) => Military_to_AMPM(rowData.dh_st_time),
      validate: (rowData) =>
        !rowData?.dh_st_time
          ? "Enter Time" //if depart time doesn't exist
          : rowData.dh_st_time <= "00:10"
          ? "Must be greater than 12:10 AM"
          : true,
      editComponent: (
        props //takes care of when we create a new project or edit an existing table
      ) => (
        <TextField
          type="time"
          variant="standard"
          value={props.value ? props.value : ""}
          helperText={props.helperText}
          onChange={(e) => props.onChange(e.target.value)}
          className={props.value ? "has-value" : ""}
          error={!props.value || props.value <= "00:10" ? true : false}
        />
      ),
    },
    {
      title: "Return Time",
      field: "dh_end_time",
      render: (rowData) => Military_to_AMPM(rowData.dh_end_time),
      validate: (rowData) =>
        !rowData?.dh_end_time
          ? "Enter Time" //if return time doesn't exist
          : true,
      editComponent: (
        props //takes care of when we create a new project or edit an existing table
      ) => (
        <TextField
          type="time"
          variant="standard"
          value={props.value ? props.value : ""}
          helperText={props.helperText}
          onChange={(e) => props.onChange(e.target.value)}
          className={props.value ? "has-value" : ""}
          error={!props.value}
        />
      ),
    },
    {
      title: "Next Day",
      field: "next_day",
      render: (rowData) => (
        <Tooltip title="Arrival time is on the next day">
          <Checkbox
            checked={
              rowData.dh_st_time >= rowData.dh_end_time || rowData.next_day
            }
            onClick={(e) => e.preventDefault()}
          />
        </Tooltip>
      ),
      editComponent: (props) => (
        <Tooltip title="Arrival time is on the next day">
          {/* wrap in span to avoid tooltip error when disabled */}
          <span>
            <Checkbox
              disabled={
                props.rowData?.dh_st_time &&
                props.rowData?.dh_end_time &&
                props.rowData.dh_st_time >= props.rowData.dh_end_time
              }
              checked={
                (props.rowData?.dh_st_time &&
                  props.rowData?.dh_end_time &&
                  props.rowData.dh_st_time >= props.rowData.dh_end_time) ||
                props.value
              }
              onClick={(e) => props.onChange(e.target.checked)}
            />
          </span>
        </Tooltip>
      ),
    },
    {
      title: fleetManualFields()[4].label,
      field: fleetManualFields()[4].key,
      render: (rowData) => (
        <>
          {roundNumber(unitMiles(rowData[fleetManualFields()[4].key]))}{" "}
          {unitWrapper(unitLargeAbbr[units])}
        </>
      ),
      customFilterAndSearch: (term, rowData) =>
        roundNumber(unitMiles(rowData[fleetManualFields()[4].key]))
          .toString()
          .includes(term),
      validate: (rowData) =>
        parseFloat(rowData[fleetManualFields()[4].key]) > 0 &&
        parseFloat(rowData[fleetManualFields()[4].key]) < 1000,
      editComponent: (
        props //takes care of when we create a new project or edit an existing table
      ) => (
        <TextField
          id={fleetManualFields()[4].key}
          name={fleetManualFields()[4].key}
          label="Distance"
          type="number"
          variant="standard"
          required
          error={
            !parseFloat(props.value) ||
            parseFloat(props.value) <= 0 ||
            parseFloat(props.value) >= 1000
          }
          helperText={
            !parseFloat(props.value)
              ? "Enter Number"
              : parseFloat(props.value) <= 0 || parseFloat(props.value) >= 1000
              ? "Between 0 and 1000"
              : ""
          }
          value={
            props.value ? roundNumber(unitMiles(parseFloat(props.value))) : ""
          }
          onChange={(e) => {
            props.onChange(unitPerMile(parseFloat(e.target.value)));
          }}
          InputProps={{
            inputProps: { max: 1000, min: 0, step: 0.01 },
            endAdornment: (
              <InputAdornment position="end">
                {unitLargeAbbr[units]}
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      title: "Route Type",
      field: "projectType",
      lookup: TYPE_STRINGS.PROJECT_TYPE,
      editable: "never",
    },
    {
      title: "Depot",
      field: "endDepot",
      lookup: depotLookup,
      // editable: "onUpdate",
      emptyValue: "Loading...",
      validate: (rowData) =>
        rowData.endDepot === "" || !rowData.endDepot ? "No Selection" : true,
      editComponent: (props) => (
        <FormControl
          fullWidth
          error={!props.value ? true : false}
          variant="standard"
        >
          <InputLabel id="depot-label">Depot</InputLabel>
          <Select
            labelId="depot-label"
            id="depot"
            name="depot"
            value={props.value || ""}
            label="Depot"
            onChange={(e) => props.onChange(parseInt(e.target.value))}
          >
            {Object.entries(depotLookup).map(([key, value]) => (
              <MenuItem key={key} value={key} onKeyDown={handleKey}>
                {value}
              </MenuItem>
            ))}
          </Select>
          {!props.value && <FormHelperText>No Selection</FormHelperText>}
        </FormControl>
      ),
    },
    {
      title: "Vehicle Type",
      field: "veh_type",
      lookup: TYPE_STRINGS.VEHICLE_TYPE,
      validate: (rowData) => {
        const validVehicles = vehicleResource
          .filter(
            //selected vehicle depot's resources must contain the desired depot
            (v) => v.depot_id === rowData.endDepot
          )
          .map((v) => v.resource_id); //convert into a list of just vehicle names

        return rowData.veh_type === "" ||
          allVehicles.filter(
            (v) => validVehicles.includes(v.model) && v.type == rowData.veh_type
          ).length <= 0 || //ensure that the selected type is among the valid vehicles in the selected depot
          !rowData.endDepot
          ? "No Selection"
          : true;
      },
      editComponent: (props) => {
        const validVehicles = vehicleResource
          .filter(
            //selected vehicle depot's resources must contain the desired depot
            (v) => v.depot_id === props.rowData.endDepot
          )
          .map((v) => v.resource_id); //convert into a list of just vehicle names

        const selectableTypes = [
          ...new Set( //remove duplicate types
            allVehicles
              //make a list of vehicle types based off of vehicles in depot
              .filter((v) => validVehicles.includes(v.model))
              .map((v) => v.type)
          ),
        ];

        return (
          <FormControl
            fullWidth
            error={
              !props.value || props.value == "" || props.helperText
                ? true
                : false
            }
            variant="standard"
          >
            <InputLabel id="veh-type-label">Type</InputLabel>
            <Select
              labelId="veh-type-label"
              id="veh-type"
              name="veh-type"
              value={props.value ? props.value : ""}
              defaultValue=""
              label="veh-type"
              onChange={(e) => props.onChange(e.target.value)}
            >
              {selectableTypes.map((val) => (
                <MenuItem key={val} value={val}>
                  {TYPE_STRINGS.VEHICLE_TYPE[val]}{" "}
                </MenuItem>
              ))}
            </Select>
            {(!props.value || props.value === "" || props.helperText) && (
              <FormHelperText>{props.helperText}</FormHelperText>
            )}
          </FormControl>
        );
      },
    },
    {
      title: "Size",
      field: "size",
      // type: "numeric",
      render: (rowData) =>
        rowData.veh_type == 1 ? (
          <>
            {unitFeet(rowData.size)} {unitWrapper(unitSmallMap[units])}
          </>
        ) : rowData.veh_type == 4 ? (
          //if selected vehicle type is a schoolbus, display Vehicle Type Character instead
          `Type ${rowData.size}`
        ) : rowData.size == 14 ? (
          "Transit"
        ) : (
          `Class ${rowData.size}`
        ),
      customFilterAndSearch: (term, rowData) =>
        (rowData.veh_type == 1
          ? unitFeet(rowData.size)
          : rowData.veh_type == 4
          ? `Type ${rowData.size}`
          : rowData.size == 14
          ? "Transit"
          : `Class ${rowData.size}`
        )
          .toString()
          .includes(term),
      validate: (rowData) => {
        const validVehicles = vehicleResource
          .filter(
            //selected vehicle depot's resources must contain the desired depot
            (v) => v.depot_id === rowData.endDepot
          )
          .map((v) => v.resource_id); //convert into a list of just vehicle names
        return rowData.veh_type === "" ||
          allVehicles.filter(
            (v) =>
              validVehicles.includes(v.model) &&
              v.type == rowData.veh_type &&
              v.size == rowData.size
          ).length <= 0 || //ensure that the selected type+size is among the valid vehicles in the selected depot
          !rowData.endDepot
          ? "No Selection"
          : true;
      },
      editComponent: (props) => {
        const validVehicles = vehicleResource
          .filter(
            //selected vehicle depot's resources must contain the desired depot
            (v) => v.depot_id === props.rowData.endDepot
          )
          .map((v) => v.resource_id); //convert into a list of just vehicle names
        const selectableSizes = [
          ...new Set( //remove duplicate types
            allVehicles
              .filter(
                //make a list of vehicle size based off of vehicles in depot and selected type
                (v) =>
                  validVehicles.includes(v.model) &&
                  v.type == props.rowData.veh_type
              )
              .map((v) => v.size)
          ),
        ];
        return (
          <FormControl
            fullWidth
            error={
              !props.value || props.value == "" || props.helperText
                ? true
                : false
            }
            variant="standard"
          >
            <InputLabel id="veh-size-label">Size</InputLabel>
            <Select
              labelId="veh-size-label"
              id="veh-size"
              name="veh-size"
              value={props.value ? props.value : ""}
              defaultValue=""
              label="veh-size"
              onChange={(e) => props.onChange(e.target.value)}
            >
              {selectableSizes.map((val) => (
                <MenuItem key={val} value={val}>
                  {props.rowData.veh_type == 1 ? (
                    <>
                      {unitFeet(val)} {unitWrapper(unitSmallMap[units])}
                    </>
                  ) : props.rowData.veh_type == 4 ? (
                    `Type ${val}`
                  ) : val == 14 ? (
                    "Transit"
                  ) : (
                    `Class ${val}`
                  )}
                </MenuItem>
              ))}
            </Select>
            {(!props.value || props.value === "" || props.helperText) && (
              <FormHelperText>{props.helperText}</FormHelperText>
            )}
          </FormControl>
        );
      },
    },
    {
      title: "Route Stop Coordinates",
      // field: "coords",
      editable: "onAdd",
      render: (rowData) => (
        <Button
          id="coords"
          name="coords"
          variant="outlined"
          className="btn"
          onClick={(e) => handleCoordOpen(e, rowData)}
          disabled={rowData.tableData?.editing === "update"}
        >
          Coordinates <br /> (Optional)
        </Button>
      ),
      editComponent: (props) => (
        <Button
          id="coords"
          name="coords"
          variant="outlined"
          className="btn"
          onClick={(e) => handleCoordOpen(e, props?.rowData)}
        >
          Coordinates
        </Button>
      ),
    },
  ];

  /**
   * Deletes all selected rows
   * @param {SubmitEvent} event
   */
  function handleBulkDelete(event) {
    event.preventDefault();

    // make a set of blockIds, for faster lookup during filter
    const selectedRowSet = new Set(selectedRows.map((row) => row.blockId));
    //removes all selected rows
    const newData = fleetData.filter((row) => !selectedRowSet.has(row.blockId));
    newData.forEach(
      (row) => (row.tableData = { ...row.tableData, checked: false }) //note: I hate that the checked status is stored in two separate places, but it'll be too much hassle to change it
    );
    setFleetData(newData);
    setSelectedRows([]);
    setIsBulkDeleteOpen(false);
    localDb?.manualInput?.bulkDelete(Array.from(selectedRowSet));
  }

  /**
   * for adding a new row to the primary table
   * (and storing that row in the indexDB)
   * @param {row} newData new row's data
   * @returns {number} 0 for resolve (accept), -1 for reject (fail to add)
   */
  const handleRouteSubmit = (newData) => {
    if (fleetData.some((row) => row.blockId === newData.blockId)) {
      //if a row already exists with the entered route ID, display error message
      snackBarElement.current.displayToast(
        `${getLabel("block_id")} already exists`,
        "error"
      );
      return -1;
    }

    const validVehicles = vehicleResource
      .filter(
        //selected vehicle depot's resources must contain the desired depot
        (v) => v.depot_id === newData.endDepot
      )
      .map((v) => v.resource_id); //convert into a list of just vehicle names
    if (
      !newData.blockId ||
      !newData.endDepot ||
      !newData.dh_st_time ||
      !newData.dh_end_time ||
      !newData.projectType ||
      !newData.distance ||
      isNaN(newData.distance) ||
      newData.distance <= 0 ||
      !newData.size ||
      !newData.veh_type ||
      !allVehicles.some(
        (v) =>
          validVehicles.includes(v.model) &&
          v.type == newData.veh_type &&
          v.size == newData.size
      )
    ) {
      //ensures valid inputs
      console.log("newData", newData);
      snackBarElement.current.displayToast(`Invalid Inputs`, "error");
      return -1;
    }

    //set route stop coordinates
    if (activeRow) {
      //if active row exists (i.e. there are unsaved edits in the coords data)
      newData.coords = activeRow.coords;
    }

    // if start time comes before end time, assume next day
    if (newData.dh_st_time >= newData.dh_end_time)
      if (
        !selectedRows.length ||
        selectedRows[0].endDepot == newData.endDepot
      ) {
        //auto-select, only if the new row's end depot matches those already selected, or if there are no rows selected yet
        newData.tableData = { checked: true };
        setSelectedRows((selectedRows) => [...selectedRows, newData]);
      }

    setActiveRow(undefined); //clear out coords data, for next time the user adds a row
    setFleetData([...fleetData, newData]);
    setInitialFormData({
      projectType: project.type,
      coords: [],
    }); //reset the initialFormData (in case of in "duplicate row" mode)

    localDb.manualInput.add({ blockId: newData.blockId, data: newData }); //store the rowData in manualInput, for revisits
    //NOTE: add returns a promise... maybe consider having a fail condition to catch errors
    setIsNavDisabled(false);
    return 0;
  };

  /**
   * updates a row primary route table and stores it in indexDB
   * @param {routeRowData} newData
   * @param {routeRowData} oldData
   * @returns {number} 0 for resolve (accept), -1 for reject (fail to add)
   */
  const handleRouteUpdate = (newData, oldData) => {
    const validVehicles = vehicleResource
      .filter(
        //selected vehicle depot's resources must contain the desired depot
        (v) => v.depot_id === newData.endDepot
      )
      .map((v) => v.resource_id); //convert into a list of just vehicle names
    if (
      !newData.blockId ||
      !newData.endDepot ||
      !newData.dh_st_time ||
      !newData.dh_end_time ||
      !newData.projectType ||
      !newData.distance ||
      isNaN(newData.distance) ||
      newData.distance <= 0 ||
      !newData.size ||
      !newData.veh_type ||
      !allVehicles.some(
        (v) =>
          validVehicles.includes(v.model) &&
          v.type == newData.veh_type &&
          v.size == newData.size
      )
    ) {
      //ensures valid inputs
      console.log("newData", newData);
      snackBarElement.current.displayToast(`Invalid Inputs`, "error");
      return -1;
    }

    if (activeRow) {
      //if the routeStopCoordinates were altered
      newData.coords = activeRow.coords;
    }

    let dataUpdate = [...fleetData];
    const index = oldData.tableData.index;

    newData.tableData = oldData.tableData;

    if (oldData.tableData.checked && newData.endDepot != oldData.endDepot) {
      //if the row was previously selected, but the depot was modified, it should be unchecked
      newData.tableData = { ...oldData.tableData, checked: false };
      setSelectedRows((selectedRows) =>
        selectedRows.filter((row) => row.blockId != oldData.blockId)
      );
    } else if (oldData.tableData.checked) {
      //if the row was previously selected and depot was not modified, update it in the selectedRows as well.
      let selectedDataUpdate = [...selectedRows];
      const selectedIndex = selectedDataUpdate.findIndex(
        (row) => row.blockId == oldData.blockId
      );
      selectedDataUpdate[selectedIndex] = newData;
      setSelectedRows(selectedDataUpdate);
    }

    dataUpdate[index] = newData;
    setActiveRow(undefined);
    setFleetData([...dataUpdate]);

    localDb?.manualInput?.update(oldData.blockId, {
      data: newData,
    }); //update the indexdb
    //NOTE: update returns a promise... maybe consider having a catch statement for failures

    setIsNavDisabled(false);
    return 0;
  };

  /**
   * FINAL SUBMIT
   * sends the primary table data to the backend routeEnergy Modeler,
   * stores the response in indexdb, and navigates to route Energy page
   */
  const handleSubmit = async () => {
    let tempArr = [];

    if (!selectedRows || selectedRows.length <= 0) {
      snackBarElement.current.displayToast("No Rows Selected", "warning");
      return;
    }
    setButtonLoading(true);

    selectedRows.forEach((el, index) => {
      //maybe turn this into a map instead of a forEach?
      const vehicleInfo = allVehicles.find(
        (vehicle) => vehicle.type == el.veh_type && vehicle.size == el.size
      );
      let dh_st_time = Military_to_MFM(el.dh_st_time);
      let dh_end_time = Military_to_MFM(el.dh_end_time);
      if (dh_end_time <= dh_st_time || el.next_day) dh_end_time += 1440;

      tempArr.push({
        id: index,
        block_id_original: el?.block_id_original,
        blockId: el.blockId,
        dh_st_time: dh_st_time,
        dh_end_time: dh_end_time,
        endTime: "N/A",
        startTime: "N/A",
        lon_start: "",
        lat_start: "",
        distance: Number(el.distance),
        vehicleEff: vehicleInfo.efficiency, //efficiency potentially modifiable in future
        vehicleModel: vehicleInfo.model,
        veh_type: el.veh_type,
        size: el.size,
        next_day: dh_end_time >= 1440,

        trips: [],
        endDepot: el.endDepot,
        startDepot: el.endDepot, //TODO: Implement something so start/end depots don't match
        coords: el.coords?.map((coordinate) => [
          +coordinate[0],
          +coordinate[1],
        ]),
      });
    });

    ///...honestly, This makes me think that we could probably just store all of the rows
    ///in a single indexDb table, instead of manualInput and blocks... But, probably for the best that we don't
    storeLocalData("blocks", { data: tempArr });

    const { data: sim } = await getLocalData("simulation", "data");

    //Save the data on the backend Body
    const backendBody = {
      id: sim.id, //The simulation ID
      current_page: stepInfo[STEP_NUMBER + 1].route,
      steps: {
        manualInput: fleetData, //save fleetData on the backend
        blocks: tempArr, //the blocks to save on the backend

        //clear out the future pages' backend data
        routeEnergy: {},
        battery: {},
        fleetSizing: {},
        evAssessment: {},
        financial: {},
        tco: {},
      },
      completed: false,
    };

    //clear out the future pages' frontend data
    partialClearLocalData([
      "routeEnergy",
      "battery",
      "fleetSizing",
      "evAssessment",
      "financial",
      "tco",
    ]);

    let headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };
    fetch(simulationURL, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(backendBody),
    })
      .then((response) => {
        if (response.ok) {
          navigate(stepInfo[STEP_NUMBER + 1].route);
        } else
          errorHandler(
            response,
            snackBarElement,
            "Error sending data to backend."
          );
      })
      .catch((e) => {
        snackBarElement.current.displayToast(
          "Error Sending Data to Backend",
          "error"
        );
        console.log("error", e);
      })
      .finally(() => setButtonLoading(false));
  };

  /**
   *  opens the "add/edit coordinates table" dialog box for a primary table row
   * @param {*} event -the "You clicked a button" event
   * @param {*} rowData - the current row data, to know which row the coordinates are being assigned to.
   */
  const handleCoordOpen = (e, rowData) => {
    e.preventDefault();

    if (!activeRow?.coords) {
      //FOR DUPLICATES/NORMAL VIEW/ADD FIRST VISIT
      //NOT FOR ADD/EDIT REPEAT VISITS
      setActiveRow(rowData); //mainly to determine if the row is new (i.e. has tableData.id) or is old/updating
    }
    setCoordTableDialogOpen(true);
  };

  /**
   * submit Route Stop Coordinates into the primary table's row,
   * and close Route Stop Coord dialog box (fires on "Submit" in dialog box)
   */
  const handleCoordSubmit = (coords) => {
    if (activeRow.tableData?.index >= 0) {
      //&& !activeRow.tableData.editing) {
      //If the primary table is not in add/edit mode
      /// put the new coords into the fleetdata for the row directly
      ////and discard the active row (which should only persist during edit/adds, for the event where a user opens the coords dialog twice in a single edit/add)
      const index = activeRow.tableData.index;

      const copyFleetData = [...fleetData];

      copyFleetData[index].coords = coords;

      localDb?.manualInput?.update(copyFleetData[index].blockId, {
        "data.coords": coords,
      }); //update the indexdb

      setFleetData(copyFleetData);
      setActiveRow(undefined);
    } else {
      setActiveRow({ ...activeRow, coords });
    }

    //otherwise, if the primary table is in add/edit mode,
    /// leave the new coords into the rowData value,
    //// to later be added to the fleetdata on primary table's row submission
    //// or discarded if primary row edit/add is cancelled
    setCoordTableDialogOpen(false);
  };

  /** For when the "Cancel" button is pressed in route Stop Coordinates
   * Scrap Route Coordinate Changes for primary Table's row,
   * and close dialog box
   */
  const handleCoordClose = () => {
    if (activeRow.tableData?.index >= 0) setActiveRow(undefined);
    setCoordTableDialogOpen(false);
  };

  /** the memoized React Component of the primary data table */
  const MemoizedRouteTable = useMemo(() => {
    return (
      <MaterialTable
        icons={Icons("Route", setIsNavDisabled)}
        columns={columns}
        localization={{
          toolbar: {
            searchPlaceholder: "Filter",
            searchTooltip: "Filter",
          },
          body: {
            emptyDataSourceMessage: fleetData?.length ? (
              "No records to display" //prevents fresh imports when performing searches for terms that don't exist
            ) : (
              <div>
                <LoadingButton
                  loading={Boolean(!allVehicles.length)}
                  variant="outlined"
                  className="btn"
                  component="label"
                >
                  Import Excel File
                  <input
                    id="file_input"
                    type="file"
                    onChange={handleImportExcel}
                    className="excel-import-input"
                    accept=".xls,.xlsx"
                  />
                </LoadingButton>
                <br />
                <br />
                <Button
                  onClick={handleDownload}
                  variant="outlined"
                  className="btn"
                  component="div"
                >
                  Download template file
                </Button>
              </div>
            ),
          },
        }}
        options={{ selection: true, showTitle: false, idSynonym: "blockId" }}
        tableRef={materialTableRef}
        initialFormData={initialFormData}
        data={fleetData}
        actions={[
          {
            icon: ContentCopyIcon,
            tooltip: "Duplicate Row",
            position: "row",
            onClick: (event, rowData) => {
              const materialTable = materialTableRef.current;
              setInitialFormData({
                ...rowData,
                tableData: { checked: rowData.tableData.checked },
                blockId: null,
              });

              materialTable.dataManager.changeRowEditing();
              materialTable.setState({
                ...materialTable.dataManager.getRenderState(),
                showAddRow: true,
              });
              setIsNavDisabled(true);
            },
            disabled: !(
              Object.keys(depotLookup).length &&
              vehicleResource.length &&
              allVehicles.length
            ),
          },
          {
            icon: () => (
              <Button
                sx={{ border: "1px solid black", borderRadius: "16px" }}
                startIcon={<Delete />}
                variant="outlined"
              >
                Delete Selected
              </Button>
            ),
            tooltip: "Delete all selected rows",
            isFreeAction: true,
            onClick: () => setIsBulkDeleteOpen(true),
            hidden: !fleetData.length,
          },
        ]}
        components={{
          Toolbar: (props) => {
            let modifiedProps = { ...props };
            modifiedProps.actions = modifiedProps.actions.concat(
              modifiedProps.actions
                .filter((action) => action.position == "toolbar")
                .map((action) => {
                  return {
                    ...action,
                    position: "toolbarOnSelect",
                  };
                })
            );
            return <MTableToolbar {...modifiedProps} />;
          },
        }}
        editable={{
          onRowAddCancelled: () => {
            setActiveRow(undefined); //if add/edit cancelled, discard activeRow
            setInitialFormData({
              projectType: project.type,
              coords: [],
            }); //reset the initialFormData (in case of in "duplicate row" mode)
            setIsNavDisabled(false);
          },
          onRowUpdateCancelled: () => {
            setActiveRow(undefined);
            setIsNavDisabled(false);
          },
          onRowAdd:
            ((Object.keys(depotLookup).length &&
              vehicleResource.length &&
              allVehicles.length) ||
              undefined) &&
            ((newData) =>
              new Promise((resolve, reject) => {
                const status = handleRouteSubmit(newData);
                if (status == 0) resolve();
                else reject();
              })),
          onRowUpdate:
            ((Object.keys(depotLookup).length &&
              vehicleResource.length &&
              allVehicles.length) ||
              undefined) &&
            ((newData, oldData) =>
              new Promise((resolve, reject) => {
                const status = handleRouteUpdate(newData, oldData);
                if (status == 0) resolve();
                else reject();
              })),
          onRowDelete:
            ((Object.keys(depotLookup).length &&
              vehicleResource.length &&
              allVehicles.length) ||
              undefined) &&
            ((oldData) =>
              new Promise((resolve, reject) => {
                const dataDelete = [...fleetData];
                const index = dataDelete.findIndex(
                  (row) => row.blockId == oldData.blockId
                );
                dataDelete.splice(index, 1);

                //if deleted row was checked, remove it from selected rows as well
                const selectedIndex = selectedRows.findIndex(
                  (row) => row.blockId == oldData.blockId
                );
                if (selectedIndex != -1) {
                  selectedRows.splice(index, 1);
                }

                setFleetData([...dataDelete]);
                localDb?.manualInput?.delete(oldData.blockId); //delete the row from indexDb
                resolve();
              })),
        }}
        onSelectionChange={(data) => {
          let temp = {};
          temp[data[0]?.endDepot] = 1;
          for (let i = 1; i < data.length; i++) {
            if (data[i].endDepot in temp) {
              temp[data[0].endDepot] += 1;
            } else break;
          }
          if (!data.length) {
            setSelectedRows([]);
            snackBarElement.current.displayToast(
              `No ${getLabel("blocks")} Selected`,
              "info"
            );
          } else if (temp[data[0].endDepot] != data.length) {
            setSelectedRows([]);
            snackBarElement.current.displayToast(
              `Multiple Depot Locations Selected`,
              "warning"
            );
          } else {
            const selectedIds = data.map((row) => row.blockId);
            setSelectedRows(data);
            //note: this setting of fleet data is NOT meant to be used as a replacement for selectedRows (unless you decide to change the implementation to match)
            setFleetData((originalRows) =>
              originalRows.map((orig) => ({
                ...orig,
                tableData: {
                  ...orig?.tableData,
                  checked: selectedIds.includes(orig.blockId),
                },
              }))
            );
            snackBarElement.current.displayToast(
              `${data.length} ${getLabel("block")}(s) added to input`
            );
          }
        }}
      />
    );
  }, [
    materialTableRef,
    fleetData,
    depotLookup,
    allVehicles,
    vehicleResource,
    initialFormData,
    coordTableDialogOpen,
  ]);

  //Final Page Render
  return (
    <div>
      <br />
      <br />
      <AssessmentAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      <br />
      <Container
        fixed
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          align="left"
          className="page-title"
        >
          Fleet&nbsp;Operations&nbsp;Input&nbsp;&#8288;&#8211;&#8288;&nbsp;Manual
        </Typography>
        <SimulationSubtitle />
      </Container>
      <br />
      {project ? (
        <>
          {/* Table Contents */}
          <Container fixed maxWidth="xl">
            <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
              {MemoizedRouteTable}
            </Paper>
          </Container>
          <br />
          <br />
          <Container>
            <NextPageButton
              style={{ width: "100%" }}
              loading={buttonLoading || !allVehicles?.length}
              disabled={!selectedRows?.length || isNavDisabled}
              onClick={handleSubmit}
            >
              Add Routes to Analysis
            </NextPageButton>
          </Container>
        </>
      ) : (
        //display loading for 1 second while retrieving and parsing project from indexDB.
        /// if no project found, display error message
        <TimedCircularProgress
          text={
            <Typography variant="h3" gutterBottom component="div">
              No Project Selected. <br />
              <Link to={stepInfo[STEP_NUMBER].route}>Select</Link> or{" "}
              <Link to="/project">Create</Link> one and come back.
              {/* No Project Selected. Select a Project at the{" "}
              <Link to={stepInfo[STEP_NUMBER].route}>Fleet Operation Input</Link> Page and come
              back. */}
            </Typography>
          }
          delay={1000} //1-second delay, as the selected project should be stored locally in IndexDB
        />
      )}

      {/* File Import Dialog */}
      <ReactSpreadsheetImport
        isOpen={isSpreadSheetImportOpen}
        onClose={onSpreadSheetImportClose}
        onSubmit={handleSpreadSheetImportSubmit}
        fields={fleetManualFields()}
        initialStepState={initialSpreadsheetState}
        matchColumnsStepHook={onMatchColumnsStep}
        rowHook={onSpreadSheetImportRowChange}
        customTheme={ReactSpreadSheetImportTheme}
      />

      <CoordInput
        isOpen={coordTableDialogOpen}
        initialCoords={activeRow?.coords || []}
        handleCoordSubmit={handleCoordSubmit}
        handleCoordClose={handleCoordClose}
      />

      {/* bulk delete dialog */}
      <Dialog
        component="form"
        open={isBulkDeleteOpen}
        onSubmit={handleBulkDelete}
        onClose={() => setIsBulkDeleteOpen(false)}
      >
        <DialogTitle>Delete Selected Rows</DialogTitle>
        <DialogContent>
          <DialogContentText textAlign="center">
            Are you sure you want to delete all the selected rows?
            <br />
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsBulkDeleteOpen(false)}>Cancel</Button>
          <Button type="submit">Delete Rows</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
